<template>
  <div class="modal" tabindex="-1" id="connectDotPadDialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="contents">
            <div class="form-title">
              <h2>{{ $t('기기연결') }}</h2>
              <button type="button" class="btn-close m-auto rounded-circle" data-bs-dismiss="modal"
                      aria-label="Close"></button>
            </div>
            <div class="form">
              <div class="form-check">
                <input type="radio" class="form-check-input" id="device1" value="BLE" v-model="selected">
                <label class="form-check-label" for="device1">Bluetooth</label>
              </div>
              <div class="form-check">
                <input type="radio" class="form-check-input" id="device2" value="COM" v-model="selected">
                <label class="form-check-label" for="device2">USB</label>
              </div>
            </div>
            <div class="form-check-inline">
              <button class="btn add-dotpad" @click="addDotPad()">Device Search</button>
            </div>
            <div style="min-height:15em">
              <table class="table mt-3">
                <thead>
                <tr class="border-bottom">
                  <th scope="col">No.</th>
                  <th scope="col">Device Name</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in dotpadJsonList" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ item.deviceName }}</td>
                  <td>
                    <button class="btn btn-warning btn-m" @click.stop.prevent="disConnect(index)">disconnect</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Dotpad} from "../js/Dotpad.js";
import {DotpadList} from "../js/DotpadList.js";

export default {
  emits: ['initializeDotPad'],
  data() {
    return {
      selected: "BLE",
      dotpadJsonList: [],
      dotpadList: [],
    }
  },
  methods:
    {
      // 닷패드 기기연결 추가
      async addDotPad() {
        const dotpad = new Dotpad();
        dotpad.disconnCallbackFtn = this.disconnCallback;
        dotpad.BLE_checked = this.selected === "BLE";

        try {
          await dotpad.request();
          await dotpad.connect();

          const deviceName = dotpad.connectedDeviceName;
          if (deviceName !== "No device Connected") {
            DotpadList.addDotPad(dotpad);
            this.$emit("initializeDotPad", dotpad);
            this.reloadDeviceList();
          }

          setTimeout(() => {
            if (dotpad.deviceInfo.deviceName === null) {
              const message = this.$t("정보를 가져올 수 없습니다.");
              this.$swal({
                title: message,
                showConfirmButton: false,
                timer: 3000
              });
            }
          }, 2000);
        } catch (error) {
          console.error(error);
        }
      },
      // 닷패드 목록 갱신
      reloadDeviceList() {
        this.dotpadJsonList = DotpadList.getJsonList();
        // let connectCount = DotpadList.getConnectCount();
        // this.$emit("connect-result", connectCount);
      },
      // 닷패드 연결해제
      disConnect(index) {
        let deviceName = this.dotpadJsonList[index].deviceName;
        let dotPad = DotpadList.getDotPad(deviceName);
        console.log("disconnected : ", deviceName, dotPad);
        if (dotPad) {
          dotPad.disconnect();
        }
      },
      disconnCallback(deviceName) {
        DotpadList.removeDotPad(deviceName);
        this.reloadDeviceList();
      }
    },
  mounted() {
    this.reloadDeviceList();
    this.dotpadList = DotpadList.getList();
  },
}
</script>

<style scoped>
.modal {
  z-index: 1056;
}

.modal-content {
  border-radius: 60px;
  background: #F7F7F7;
  position: relative;
}

.modal-content .form-title h2 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #44403F;
  margin: 0 auto;
}

.modal-content .form-title .btn-close {
  position: absolute;
  top: 20px;
  right: 26px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #EA5414 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  padding: 0;
  opacity: 1;
  box-shadow: none;
}

.modal-content .form-title .btn-close:hover {
  opacity: 0.8;
}

.form-check-input:checked {
  background-color: #EA5414;
  border-color: #EA5414;
  background-image: none;
}

.form {
  margin-top: 40px;
}

.form .form-check {
  font-size: 18px;
  margin-bottom: 20px;
}

.form-check-inline {
  position: absolute;
  top: 110px;
  right: 26px;
  margin-right: 0;
}

.form-check-inline .add-dotpad {
  background: #ea5414;
  font-size: 18px;
  color: #fff;
  width: 180px;
  border-radius: 40px;
}

.form-check-inline .add-dotpad:hover {
  opacity: 0.8;
}

table tr {
  line-height: 48px;
}

table tr td button {
  font-size: 18px;
  background: #aaabab;
  border-color: #aaabab;
  border-radius: 40px;
  color: #fff;
}

table tr td button:hover, table tr td button:active, table tr td button:focus {
  opacity: 0.8;
  background: #aaabab !important;
  border-color: #aaabab !important;
  color: #fff !important;
}
</style>
