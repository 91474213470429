// SYNC(2) : 0xAA, 0x55 (고정)
// LENGTH(2) : 0x00, 0x07 (DEST~CHECK 길이)
// DEST_ID(1) : 0x0? (CELL LINE 값)
// COMMAND(2) : 0x02, 0x00 (고정 : DISPLAY CMD)
// SEQ_NUM(1) : 0x00 (고정 : 그래픽, 텍스트 (0x80))
// OFFSETT?(1) : 0x?? (cell 위치 지정)
// DATA(1) : N
// CHECK_SUM(1) : 계산

let reader;

import {DotpadSDK} from "./DotpadSDK.js";

// import {DotpadList} from "./DotpadList.js";
class Dotpad { // eslint-disable-line no-unused-vars
    dotPadSendModule = null;
    lastKeyPressed;

    constructor() {
        this.device = null;
        this.onDisconnected = this.onDisconnected.bind(this);
        this.BLE_checked = true;
        this.connected = false;
        this.connectedDeviceName = "No device Connected";
        this.KeyInputCallbackFtn = null;
        this.disconnCallbackFtn = null;
        this.dotpadSDK = new DotpadSDK(this);
        this.characteristic = null; // bluetooth only
        this.lastKeyPressed = {name: null, keyPressTime: null, keyUpTime: null, pressedTime: null};
        this.responseHex = "";
        this.deviceInfo = {
            deviceName: null,
            firmwareVersion: null,
            hardwareVersion: null
        }
        this.deviceInfoFtn = null;
        this.functionKeyState = 0;
        this.functionKeyRelease = true;
        this.perkinsKeyState = 0;
        this.perkinsKeyRelease = true;
    }

    async request() {
        const filters = [{usbVendorId: 1027, usbProductId: 24592}, {usbVendorId: 1027, usbProductId: 24597}];
        const serialPorts = await navigator.serial.getPorts();
        const matchedSerialPorts = serialPorts.filter(port => {
            const info = port.getInfo();

            return filters.some(filter => filter.usbVendorId === info.usbVendorId && filter.usbProductId === info.usbProductId);
        });

        // Bluetooth or USB 선택
        if (this.BLE_checked) {
            // Request access to Bluetooth devices
            if ('bluetooth' in navigator) {
                // Filter matched Serial Ports devices
                if (matchedSerialPorts.length > 0) {
                    // Serial port is connected
                    console.log('Serial port is connected');
                    // USB 케이블로 PC에 연결된 상태에서 닷 패드를 Bluetooth로 검색할 때, 아무런 블루투스 장비가 검색되지 않게 Dummy data 입력
                    await navigator.bluetooth.requestDevice({
                        filters: [{services: ['00001234-0000-1000-8000-00805f9b34fb']}]
                    });
                } else {
                    // Serial port is not connected
                    console.log('Serial port is not connected');
                    const bluetoothOption = {
                        "filters": [{
                            "namePrefix": "DotPad"
                        }],
                        "optionalServices": ["49535343-fe7d-4ae5-8fa9-9fafd205e455"]
                    };
                    this.device = await navigator.bluetooth.requestDevice(bluetoothOption);
                }
            } else {
                console.log('Web Bluetooth API is not supported in this browser');
            }
        } else {
            if ('serial' in navigator) {
                this.device = await navigator.serial.requestPort({filters: filters});
            } else {
                console.log('Web Serial API is not supported in this browser');
            }
        }

        if (!this.device)
            throw "No device selected";

        if (this.BLE_checked)
            this.device.addEventListener('gattserverdisconnected', this.onDisconnected, {once: true});
        else
            this.device.addEventListener('disconnect', this.disconnect.bind(this), {once: true});
    }

    async connect() {
        if (!this.device)
            return Promise.reject('Cannot connect to device. No device selected.');

        //BLE or USB 선택
        if (this.BLE_checked) {
            let retryCount = 0;
            while (retryCount < 3) {
                try {
                    console.log('connect() : ' + this.device);
                    const server = await this.device.gatt.connect();
                    const service = await this.device.gatt.getPrimaryService("49535343-fe7d-4ae5-8fa9-9fafd205e455");
                    this.characteristic = await service.getCharacteristic("49535343-1e4d-4bd9-ba61-23c647249616");
                    this.characteristic.startNotifications()
                        .then(() => {
                            console.log('> Notifications started');
                            return this.characteristic.addEventListener('characteristicvaluechanged', this.handleNotifications.bind(this));
                        })
                        .then(this.getDeviceInfo.bind(this));
                    this.connected = true;
                    this.connectedDeviceName = server.device.name;
                    break;
                } catch (error) {
                    // 에러 처리
                    console.error(`Connection attempt ${retryCount + 1} failed: ${error.message}`);
                    retryCount++;
                }
            }
        } else {
            try {
                await this.device.open({baudRate: 115200, dataBits: 8});
                const {usbProductId, usbVendorId} = await this.device.getInfo();
                const handleReadData = this.getHandleReadData()
                // Listen for the event.
                this.device.addEventListener("change", handleReadData);
                // Dispatch the event.
                this.device.dispatchEvent(new CustomEvent("change"));
                this.getDeviceInfo();
                this.connected = true;
                this.connectedDeviceName = usbProductId + " : " + usbVendorId + " connected";
            } catch (e) {
                console.log(e.toString())
                this.connected = false;
                this.connectedDeviceName = "No device Connected";
            }
        }
    }

    // 시리얼포트 전용 커스텀 이벤트
    async getHandleReadData() {
        reader = this.device.readable.getReader();
        try {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const {value, done} = await reader.read();

                if (done) {
                    // |reader| has been canceled.
                    break;
                }
                // Do something with |value|…
                this.handleNotifications(value);
            }
        } catch (error) {
            // Handle |error|…
        } finally {
            reader.releaseLock();
        }
    }

    handleNotifications(event) {
        const keyEventMap = {
            PERKINS_KEY: "aa55000900031200",
            FUNCTION_KEY: "aa55000900033200",
        };
        const deviceInfoMap = {
            RSP_FW_VER: /aa55000d00000100.*/,
            RSP_HW_VERSION: /aa55000600001100.*/,
            RSP_DEV_NAME: /aa55(....)00010100.*/,
        };
        const ackPattern = /aa550006(..)0201(..)00.*/;
        const notiPattern = /aa550006(..)0202(..)00.*/;
        const value = event instanceof Event ? event.target.value : event;
        // Convert raw data bytes to hex string
        let hexStr = Array.from(new Uint8Array(value.buffer))
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');

        if (this.responseHex.length + hexStr.length < 18) {
            this.responseHex = `${this.responseHex}${hexStr}`;
            hexStr = "";
        } else {
            hexStr = `${this.responseHex}${hexStr}`;
            this.responseHex = "";
        }

        const packetArray = this.extractPackets(hexStr);

        for (let packet of packetArray) {
            if (this.isKeyEventPacket(keyEventMap, packet)) {
                this.processKeyEvent(keyEventMap, packet);
            } else if (this.isDeviceInfoPacket(deviceInfoMap, packet)) {
                this.processDeviceInfo(deviceInfoMap, packet);
            } else if (packet.startsWith('abcdef')) { // Check for disconnected event
                console.log('Device disconnected');
                this.device.disconnect();
            }

            const ackPacket = this.isAckPacket(ackPattern, packet);
            if (ackPacket) {
                this.handleAckPacket(ackPacket);
            }

            // USB일 경우 NOTI 체크
            if (!this.BLE_checked) {
                const notiPacket = this.isAckPacket(notiPattern, packet);
                if (notiPacket) {
                    if (this.dotPadSendModule) {
                        this.dotPadSendModule.setDotCommandSendReady(true);
                    }
                }
            }
        }
    }

    isKeyEventPacket(keyEventMap, packet) {
        return Object.values(keyEventMap).some(keyEventPattern => packet.startsWith(keyEventPattern));
    }

    processKeyEvent(keyEventMap, packet) {
        const keyEvent = Object.keys(keyEventMap).find(type => packet.startsWith(keyEventMap[type]));

        if (keyEvent) {
            if (keyEvent === "PERKINS_KEY") {
                const perkinsKeyData = parseInt(packet.substr(19, 1), 16);

                if (this.perkinsKeyRelease) {
                    this.perkinsKeyRelease = false;
                    this.lastKeyPressed.keyPressTime = new Date();
                }

                if (this.perkinsKeyState < perkinsKeyData) {
                    this.perkinsKeyState = perkinsKeyData;
                } else if (perkinsKeyData === 0) {
                    this.perkinsKeyRelease = true;
                    this.lastKeyPressed.keyUpTime = new Date();
                }
            } else if (keyEvent === "FUNCTION_KEY") {
                const functionKeyData = parseInt(packet.substr(16, 1), 16);

                if (this.functionKeyRelease) {
                    this.functionKeyRelease = false;
                    this.lastKeyPressed.keyPressTime = new Date();
                }

                if (this.functionKeyState < functionKeyData) {
                    this.functionKeyState = functionKeyData;
                } else if (functionKeyData === 0) {
                    this.functionKeyRelease = true;
                    this.lastKeyPressed.keyUpTime = new Date();
                }
            }

            if (this.perkinsKeyRelease && this.functionKeyRelease) {
                this.lastKeyPressed.pressedTime = this.lastKeyPressed.keyUpTime.getTime() - this.lastKeyPressed.keyPressTime.getTime();
                if (this.perkinsKeyState === 6) {
                    if (this.functionKeyState === 0) {
                        this.lastKeyPressed.name = "Left Pan + Right Pan";
                    }
                } else if (this.perkinsKeyState === 4) {
                    if (this.functionKeyState === 8) {
                        this.lastKeyPressed.name = "Left Pan + Function 1";
                    } else if (this.functionKeyState === 0) {
                        this.lastKeyPressed.name = "Left Pan";
                    }
                } else if (this.perkinsKeyState === 2) {
                    if (this.functionKeyState === 1) {
                        this.lastKeyPressed.name = "Function 4 + Right Pan";
                    } else if (this.functionKeyState === 0) {
                        this.lastKeyPressed.name = "Right Pan";
                    }
                } else if (this.perkinsKeyState === 0) {
                    const functionKeyNameMap = {
                        12: "Function 1 + Function 2",
                        8: "Function 1",
                        6: "Function 2 + Function 3",
                        4: "Function 2",
                        3: "Function 3 + Function 4",
                        2: "Function 3",
                        1: "Function 4"
                    };
                    this.lastKeyPressed.name = functionKeyNameMap[this.functionKeyState];
                }

                if (this.KeyInputCallbackFtn) {
                    this.KeyInputCallbackFtn(this, this.lastKeyPressed);
                }

                this.perkinsKeyState = 0;
                this.functionKeyState = 0;
            }
        }
    }

    isDeviceInfoPacket(deviceInfoMap, packet) {
        return Object.values(deviceInfoMap).some(deviceInfoPattern => packet.match(deviceInfoPattern));
    }

    processDeviceInfo(deviceInfoMap, packet) {
        const deviceInfo = Object.keys(deviceInfoMap).find(type => packet.match(deviceInfoMap[type]));

        if (deviceInfo) {
            const lastIndex = 16;
            const deviceResponseData = packet.substring(lastIndex, packet.length - 2);
            const deviceResponseAscii = this.hexToAscii(deviceResponseData);
            switch (deviceInfo) {
                case 'RSP_FW_VER':
                    this.deviceInfo.firmwareVersion = deviceResponseAscii;
                    break;
                case 'RSP_HW_VERSION':
                    this.deviceInfo.hardwareVersion = deviceResponseAscii;
                    break;
                case 'RSP_DEV_NAME':
                    this.deviceInfo.deviceName = deviceResponseAscii;
                    break;
            }

            if (this.deviceInfoFtn != null) {
                this.deviceInfoFtn();
            }
        }
    }

    isAckPacket(ackPattern, packet) {
        return packet.match(ackPattern);
    }

    handleAckPacket(ackPacket) {
        const ackValue = parseInt(ackPacket[1], 16);

        if (this.dotPadSendModule) {
            this.dotPadSendModule.setDotPadLineReceiveAck(ackValue, true);
            // BLE일 경우 NOTI 체크안함
            if (this.BLE_checked) {
                this.dotPadSendModule.setDotCommandSendReady(true);
            }
        }
    }

    extractPackets(packetHexString) {
        let packets = [];
        let currentIndex = 0;

        while (currentIndex < packetHexString.length) {
            if (packetHexString.length - currentIndex < 18) {
                // 패킷의 길이가 12보다 작을 경우 남은 데이터는 패킷이 아님
                this.responseHex = packetHexString.substring(currentIndex);
                break;
            }

            const startIndex = currentIndex + 4;
            const endIndex = startIndex + 4;
            const lengthHex = packetHexString.substring(startIndex, endIndex);

            const length = parseInt(lengthHex, 16);
            if (isNaN(length)) {
                // 길이를 16진수에서 10진수로 변환할 수 없음
                this.responseHex = packetHexString.substring(currentIndex);
                break;
            }

            const packetStartIndex = currentIndex + 8;
            const packetEndIndex = packetStartIndex + length * 2;
            const packet = packetHexString.substring(currentIndex, packetEndIndex);

            if(packet.length === length * 2 + 8){
                packets.push(packet);
                currentIndex = packetEndIndex;
            } else {
                this.responseHex = packetHexString.substring(currentIndex);
                break;
            }
        }

        return packets;
    }

    printDTM(pinData) {
        for (let i = 0; i < 20; i++) {
            console.log('printDTM : ' + pinData[i]);
        }
    }

    async writeCmdTest(cmdCode) {
        try {
            if (this.BLE_checked) {
                await this.characteristic.writeValueWithoutResponse(cmdCode);
                return true;
            } else {
                const writer = this.device.writable.getWriter();
                await writer.write(cmdCode);
                writer.releaseLock();
                return true;
            }
        } catch (e) {
            console.error(e);
        }
    }

    disconnect() {
        if (!this.device) {
            return Promise.reject('Device is not connected.');
        }

        if (this.BLE_checked) {
            return this.device.gatt.disconnect();
        } else {
            if (reader) {
                return reader.cancel()
                    .catch(() => console.error('Could not ReadableStream cancel.'))
                    .then(() => reader = null)
                    .then(() => this.device.close())
                    .then(() => this.onDisconnected())
                    .catch((error) => console.error(error));
            }
        }
    }

    onDisconnected() {
        console.log('Device is disconnected.');
        this.disconnCallbackFtn(this.connectedDeviceName);
    }

    setDotPadSendModule(dotPadSendModule) {
        this.dotPadSendModule = dotPadSendModule;
    }

    async getDeviceName() {
        this.deviceInfoFtn = this.getFirmwareVersion;
        const hexToBytes = `${"00"}${"0100"}${"00"}`;

        await this.sendDeviceInfoCommand(hexToBytes);
    }

    async getFirmwareVersion() {
        this.deviceInfoFtn = this.getHardwareVersion;
        const hexToBytes = `${"00"}${"0000"}${"00"}`;

        await this.sendDeviceInfoCommand(hexToBytes);
    }

    async getHardwareVersion() {
        this.deviceInfoFtn = null;
        const hexToBytes = `${"00"}${"0010"}${"00"}`;

        await this.sendDeviceInfoCommand(hexToBytes);
    }

    async sendDeviceInfoCommand(hexToBytes) {
        const dot_protocol_header = `${"AA55"}${"0005"}`;
        const checksum = this.dotpadSDK.checksum(hexToBytes);
        const hexStringToArrayBuffer = this.dotpadSDK.hexStringToArrayBuffer(`${dot_protocol_header}${hexToBytes}${checksum}`);

        await this.writeCmdTest(hexStringToArrayBuffer);
    }

    getDeviceInfo() {
        this.getDeviceName()
            .then(() => new Promise(resolve => setTimeout(resolve, 2000))) // Delay for 2 second
            // Disconnect if no device is connected.
            .then(() => {
                console.log(this.deviceInfo);
                if (this.deviceInfo.deviceName === null || this.deviceInfo.deviceName === undefined) {
                    this.disconnect();
                }
            });
    }

    hexToAscii(hex) {
        let ascii = '';
        for (let i = 0; i < hex.length; i += 2) {
            const hexByte = hex.substr(i, 2);
            const decimalValue = parseInt(hexByte, 16);
            ascii += String.fromCharCode(decimalValue);
        }
        return ascii;
    }
}

export {
    Dotpad
}
